import { useSelector } from "react-redux";
import { useTranslation } from "../../../../../hooks";
import { Languages } from "../../../../../components/ui/Languages/Languages";
import { Notifications } from "../Notifications/Notifications";
import { ThemeTriger } from "../ThemeTriger/ThemeTriger";
import { Button } from "../../../../ui/Buttons/Button/Button";
import { Skeleton } from "../../../../ui/Skeleton/Skeleton";

export const Actions = ({ loginFormHandler, isMobileMenu = false }) => {
  const { user } = useSelector((state) => state.content);
  const { header: { cabinet } } = useTranslation();

  // eslint-disable-next-line no-nested-ternary
  const buttonContent = !user?.isAuthLoaded
    ? <Skeleton width={100} height={18} rounded={2} uniqueKey="login-button" />
    : user?.isAuth ? cabinet?.loginIsAuth : cabinet?.login;

  return (
    <div className="header-actions">
      <div className="header-actions__container">
        <div className="media__min-width-993--flex">
          <Languages menuDirection={"bottom"} />
        </div>
        <div className="media__max-width-992">
          <Languages menuDirection={"top"} />
        </div>
        {!isMobileMenu && <Notifications />}
        <ThemeTriger />
      </div>
      <Button
        initClass="header__cabinet-button"
        tab="dark-gradient"
        aria-label={user?.isAuth ? cabinet?.loginIsAuth : cabinet?.login}
        onClick={loginFormHandler}
        disabled={!user?.isAuthLoaded}
      >
        {buttonContent}
      </Button>
    </div>
  );
};
