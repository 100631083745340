import { useSelector } from "react-redux";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom";
import { useCallback, useEffect } from "react";
import { useDispatchedActions } from "../../../hooks/useDispatchedActions";
import { useTranslation } from "../../../hooks/useTranslation";
import { useResize } from "../../../hooks/useResize";
import { DesktopMenu } from "./components/DesktopMenu/DesktopMenu";
import { MobileMenu } from "./components/MobileMenu/MobileMenu";
import { Modals } from "./components/Modals/Modals";
import "./Header.scss";

export const Header = () => {
  // **Props
  const { width, height } = useResize();

  const { lang } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const registration = searchParams.get("registration");
  // Dispatch
  const { setMenu, setLoginModal, setRegisterModal, setActiveCountry } =
    useDispatchedActions();

  // **State
  const { isMenuOpened, skin } = useSelector((state) => state.header);
  const { user, activeData } = useSelector((state) => state.content);

  const {
    header: { logoAlt, logoAria, ariaBurger }
  } = useTranslation();

  const menuHandler = (event) => {
    event.stopPropagation();
    setMenu(!isMenuOpened);
  };

  const clickedOnMenu = (target) => target.closest(".header-dropdown-mobile-menu") || target.classList.contains("header__dropdown-list-proxy") || target.closest(".header__go-back") || target.closest(".header__dropdown-arrow");

  const closeMenu = useCallback(({ target }) => {
    if (clickedOnMenu(target)) {
      return;
    }

    setMenu(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMenu(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  useEffect(() => {
    if (isMenuOpened) {
      window.addEventListener("click", closeMenu);
    } else {
      window.removeEventListener("click", closeMenu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpened]);

  // Show Login Modal
  const loginFormHandler = () => {
    setMenu(false);

    if (user?.isAuth) {
      navigate(`/${lang}/dashboard/?tab=profile`);
    } else {
      setLoginModal(true);
    }
  };

  useEffect(() => {
    if (registration === "open") {
      setRegisterModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registration]);

  // useEffect(() => {
  //   if (isMobileMenuOpened) {
  //     // console.log("asas")
  //     document.body.style.overflow = "hidden !importent";
  //   } else {
  //     document.body.style.overflow = "";
  //   }
  // }, [isMobileMenuOpened]);

  const clearActiveCountry = () => {
    if (!activeData?.country?.id) {
      return;
    }

    setActiveCountry({ id: "", code: "" });
  };
  return (
    <>
      <header id="header">
        <div className="header">
          <div className="container">
            <div className="header__wrapper">
              <div className="header__logo">
                <Link
                  to={`/${lang}/`}
                  aria-label={logoAria}
                  onClick={clearActiveCountry}
                >
                  <img
                    src={
                      skin === "dark"
                        ? "/img/ui/new_logo_v2.svg"
                        : "/img/ui/new_logo-light_v2.svg"
                    }
                    loading="lazy"
                    // width="170"
                    // height="50"
                    alt={logoAlt}
                  />
                </Link>
              </div>
              <DesktopMenu loginFormHandler={loginFormHandler}/>
              <div className="header__container-992">
                <button className="header__burger" onClick={menuHandler}>
                  {isMenuOpened ? (
                    <img
                      src="/img/ui/new_menu-open.svg"
                      loading="lazy"
                      width="24"
                      height="24"
                      alt={ariaBurger}
                    />
                  ) : (
                    <img
                      src="/img/ui/new_menu.svg"
                      loading="lazy"
                      width="24"
                      height="24"
                      alt={ariaBurger}
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
          {/*<Container>*/}
          {/*  */}
          {/*</Container>*/}
        </div>
        <div
          className={`header-dropdown ${
            isMenuOpened ? "header-dropdown-open" : ""
          }`}
        >
          <div className="header-dropdown-mobile-menu media__max-width-992">
            <MobileMenu loginFormHandler={loginFormHandler}/>
            {/* <Container> */}
            {/*<div className="header__container-992">*/}
            {/*  */}
            {/*</div>*/}
            {/* </Container> */}
          </div>
        </div>
        <Modals/>
      </header>
    </>
  );
};
